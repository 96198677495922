import React, { useState, useEffect } from "react";
import client from "./client/Client";
import CheckboxInput from "./shared/CheckboxInput";
import ErrorMessages from "./shared/ErrorMessages";
import PasswordInput from "./shared/PasswordInput";

import { renderLabel, renderCheckboxLabel, renderSimpleLabel } from "./shared/Utilities";

export default function Registration() {
  const [busy, setBusy] = useState(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [emailOptIn, setEmailOptIn] = useState(true);

  const [brandName, setBrandName] = useState('');
  const [brandLandingMessage, setBrandLandingMessage] = useState('');
  const [brandLandingImageUrl, setBrandLandingImageUrl] = useState('');
  const [brandLandingAltImageUrl, setBrandLandingAltImageUrl] = useState('');
  
  const [emailOptInText, setEmailOptInText] = useState(undefined);
  const [emailOptInAltText, setEmailOptInAltText] = useState(undefined);
  
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    const response = await client.getData("/user-portal/registration");
    
    setBrandName(response.result?.brand_name);

    setBrandLandingImageUrl(response.result?.brand_landing_image_url);
    setBrandLandingAltImageUrl(response.result?.brand_landing_alt_image_url);
    setBrandLandingMessage(response.result?.brand_landing_message);

    setEmailOptInText(response.result?.email_opt_in_text);
    setEmailOptInAltText(response.result?.email_opt_in_alt_text);

    setBusy(false);
  }

  const handleRegisterClick = async () => {
    setBusy(true);

    const response = await client.postData("/user-portal/registration", {
      email: email,
      password: password,
      password_confirmation: passwordConfirmation,
      email_opt_in: emailOptIn
    });

    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setBusy(false);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleRegisterClick();
  }

  return (
    <div className='container'>
      <div className="row">
        <div className="col-md-12 mt-3 mb-2">
          <div className="text-center green-text">
            <img src="/assets/logo.png" height="120px"/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 offset-md-2 mb-4">
          <div className="card-cascade wider mb4">
            <div className="card-body card-body-cascade text-center">
              { brandName && (
                <h4 className="card-title">
                  Preferred Partner
                  <hr />
                  {brandLandingImageUrl && (
                    <img src={brandLandingImageUrl} className="width-150px" />
                  )}
                </h4>
              )}
              {brandLandingMessage ? (
                <>
                  <p className="card-text">
                    <div dangerouslySetInnerHTML={{ __html: brandLandingMessage }} />
                  </p>
                  <hr />
                  <p className="card-text">
                    There are no signup fees or membership fees to join. There are no long-term contracts
                    to sign and no hidden fees.
                  </p>
                  <p className="card-text">
                    <div className="mb-1">
                      Need help?
                    </div>
                    <div className="mb-1">
                      Email: <a href='mailto:support@myopenroads.com'>support@myopenroads.com</a>
                    </div>
                    <div className="mb-1">
                      Phone: <a href='tel:18002755089'>1-800-275-5089</a>
                    </div>
                  </p>
                </>
              ) : (
                <>
                  <h4 className="card-title">
                    <strong>Signup for Open Roads</strong>
                  </h4>
                  <h5 className="blue-text pb-2">
                    <strong>RV Fuel Discount Program</strong>
                  </h5>
                  <p className="card-text">
                    By joining our program, you will have access to discount diesel prices at specified
                    locations such as Love's and Travel Centers of America. Current members are saving
                    30 to 40 cents per gallon off the pump price.
                  </p>
                  <p className="card-text">
                    There are no signup fees or membership fees to join. There are no long-term contracts
                    to sign and no hidden fees.
                  </p>
                  <p className="card-text">
                    <div className="mb-1">
                      Need help?
                    </div>
                    <div className="mb-1">
                      Email: <a href='mailto:support@myopenroads.com'>support@myopenroads.com</a>
                    </div>
                    <div className="mb-1">
                      Phone: <a href='tel:18002755089'>1-800-275-5089</a>
                    </div>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-2 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="text-center green-text">
                Register a <strong><u>user account</u></strong> and fill an <strong><u>application form</u></strong> to get started.
                <hr className="my-4" />
              </div>

              <ErrorMessages errorMessages={errorMessages} />

              <form onSubmit={onFormSubmit}>
                <div className="md-form">
                  <i className="fas fa-envelope prefix grey-text"></i>
                  <input id='email' type='text' value={email} onChange={(e) => setEmail(e.target.value)} className="form-control"/>
                  {renderLabel('Your email', email, 'email')}
                </div>
                <div className="md-form">
                  <i className="fas fa-lock prefix grey-text"></i>
                  <PasswordInput value={password} onChange={(val) => setPassword(val)} id='password' />
                  {renderLabel('Your password', password, 'password')}
                </div>
                <div className="md-form">
                  <i className="fas fa-lock prefix grey-text"></i>
                  <PasswordInput value={passwordConfirmation} onChange={(val) => setPasswordConfirmation(val)} id='password-confirmation' />
                  {renderLabel('Password confirmation', passwordConfirmation, 'password-confirmation')}
                </div>
                {emailOptInText && (
                  <div className="mb-3">
                    <CheckboxInput 
                      checked={emailOptIn}
                      onChange={(val) => setEmailOptIn(val)}
                      id='email-opt-in'
                      label={emailOptInText}
                    />
                  </div>
                )}
                {emailOptInAltText && (
                  <div dangerouslySetInnerHTML={{__html: emailOptInAltText}} className="font-weight-200 text-center mb-3">
                  </div>
                )}
                <div className="text-center">
                  <button type="submit" className="btn btn-success waves-effect waves-light" onClick={handleRegisterClick} disabled={busy}>Register</button>
                </div>
              </form>
            </div>
            <div className="modal-footer justify-content-center">
              <div className="options">
                <p className="text-muted">
                  Already a registered user?&nbsp;&nbsp;
                  <a href="/user-portal/login">Sign In</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {brandLandingAltImageUrl && (
        <div className="row">
          <div className="col-md-8 offset-md-2 mt-4">
            <img src={brandLandingAltImageUrl} className="w-100" />
          </div>
        </div>                    
      )}
  </div>
  );
}
