import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";
import StripeErrors from "./shared/StripeErrors";
import StripeFieldErrors from "./shared/StripeFieldErrors";

import { renderLabel } from "./shared/Utilities";
import { accountTypes } from "./shared/AccountTypes";

export default function Bank() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [stripeErrors, setStripeErrors] = useState([]);

  const [bankName, setBankName] = useState();
  const [bankRoutingNumber, setBankRoutingNumber] = useState();
  const [bankAccountNumber, setBankAccountNumber] = useState();
  const [accountType, setAccountType] = useState('C');

  const [accountHolderName, setAccountHolderName] = useState(null);
  const [accountHolderType, setAccountHolderType] = useState(null);

  const [stripePk, setStripePk] = useState(null);

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/bank");
    
    setBankName(response.result?.bank_account?.bank_name)
    setBankRoutingNumber(response.result?.bank_account?.bank_routing_number)
    setBankAccountNumber(response.result?.bank_account?.bank_account_number)
    setAccountType(response.result?.bank_account?.account_type || 'C')
    setStripePk(response.result?.stripe_pk);

    setAccountHolderName(`${response.result?.fuel_program_application.first_name} ${response.result?.fuel_program_application.last_name}`);
    setAccountHolderType('individual');

    setBusy(false);
  }

  const save = async () => {
    setSaveBusy(true);

    setErrorMessages([]);
    setStripeErrors([]);

    const stripe = Stripe(stripePk);

    const stripeResult = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: bankRoutingNumber || '0',
      account_number: bankAccountNumber || '0',
      account_holder_name: accountHolderName,
      account_holder_type: accountHolderType
    });

    if (stripeResult.error) {
      setStripeErrors([stripeResult.error]);
    } else {
      const request = {
        bank_account: {
          bank_name: bankName || '',
          bank_routing_number: bankRoutingNumber || '',
          bank_account_number: bankAccountNumber || '',
          account_type: accountType || '',
          stripe_token_id: stripeResult.token.id
        }
      };
  
      const response = await client.postData("/user-portal/fpa/bank", request);

      if (response.status === client.Error) {
        setErrorMessages(response.error_messages);
      }
    }

    setSaveBusy(false);
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Bank</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />
          
          <div className="row">
            <div className="col-md-6">
              <div className="md-form">
                <input id='bank-name' value={bankName} onChange={(e) => setBankName(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Bank Name', bankName, 'bank-name')}
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <select value={accountType} onChange={(e) => setAccountType(e.target.value)} className="browser-default custom-select custom-select-sm">
                    {accountTypes.map((accountType, index) => (
                      <option key={`account_type_${index}`} value={accountType[1]}>{accountType[0]}</option>
                    ))}
                </select>
                <label className="active">
                  Account Type
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="md-form">
                <input id='bank-routing-number' value={bankRoutingNumber} onChange={(e) => setBankRoutingNumber(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Bank Routing Number', bankRoutingNumber, 'bank-routing-number')}
                <StripeFieldErrors stripeErrors={stripeErrors} fieldLabel='Routing Number' stripeFieldName='bank_account[routing_number]' />
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input id='bank-account-number' value={bankAccountNumber} onChange={(e) => setBankAccountNumber(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Bank Account Number', bankAccountNumber, 'bank-account-number')}
                <StripeFieldErrors stripeErrors={stripeErrors} fieldLabel='Account Number' stripeFieldName='bank_account[account_number]' />
              </div>
            </div>
          </div>

          <StripeErrors stripeErrors={stripeErrors} />

          <div className="row mt-5">
            <div className="col-md-12">
              <button onClick={save} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Next
                <div className="Ripple"></div>
              </button>
            </div>
          </div>
        </>
      )}
      </div>
    </>
  </div>
  );
}
